import API from '../common/Api'

const CONFIG_ENDPOINT = 'configurations';

export default class ConfigurationAPI {

  static getSaieveYield() {
    return API.get(CONFIG_ENDPOINT + '/yield-saieve');
  }

}
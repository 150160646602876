import React from 'react';
import { useTranslation, Trans } from "react-i18next";
import Vector from '../images/vector.png';

const Investing = () => {
  const { t } = useTranslation('home')

  return (
    <>
      <div className="invest-section">

        <div className="title-sec">
          <h3>
            <Trans
              i18nKey="home:investing.title"
              components={[<span>univers</span>]}
            />
          </h3>
          <p>{t('investing.desc')}</p>
        </div>
        <div className="invest-form">
          <a
            href="https://app.saieve.io/account/register"
            className="btn btn-secondary"
            style={{textTransform: 'unset'}}
          >
            { t('button.get_started') }
            <img src={Vector} alt="vector"/>
          </a>
        </div>
      </div>
    </>
  )
}
export default Investing;
 
import React, {useEffect, useState} from 'react';
import {useTranslation, Trans} from "react-i18next";
import background from '../../images/whole-bg.png';
import wave from '../../images/wave.png';
import app from '../../images/app.png';
import {Container, Row, Col} from 'reactstrap';
import Vector from "../../images/square.svg";


const Build = () => {
  const { t } = useTranslation('home')
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const clientWidth = window.innerWidth || document.documentElement.clientWidth;
    if (clientWidth < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  return (
    <>
      <section className="build-section" style={{backgroundImage: `url(${background})`}}>
        <img src={wave} className="wave" alt=""/>
        <Container>
          <Row>
            <Col sm="6">
              <div className="ticker-wrapper-h">
                <div className="heading">{t('build.new')}</div>

                <ul className="news-ticker-h">
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://app.saieve.io/user/referral">
                      {t('build.referral')}
                    </a>
                  </li>
                </ul>
              </div>
              <span>{t('build.transparency')}</span>
              <span>{t('build.hidden_fees')}</span>
              <h3>{t('build.title')}</h3>
              <p><Trans i18nKey="home:build.sub_title"/></p>
              <div className="login-btns">
                <a href="https://app.saieve.io/account/register" className="start-earn">
                  {t('button.get_started')}
                  <img src={Vector} alt="vector"/>
                </a>
                {
                  isMobile ?
                    <a href="#crypto-calculator" className="log">{t('build.calculate')}</a>
                    :
                    <a href="#calculator" className="log">{t('build.calculate')}</a>
                }
              </div>
            </Col>
            <Col md="6" className="right-img">
              <div className="position-relative">
                <img src={app} alt="" className="top-vact"/>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default Build;

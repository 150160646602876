import React from 'react';
import {useTranslation, Trans} from "react-i18next";
import Vector from '../../images/square.svg';
import check from '../../images/check.svg';
import {Container, Row, Col} from 'reactstrap';

const video = require('../../images/videos/SAEIVE-growth-loop.mp4');
const Grow = () => {
  const { t } = useTranslation('home')

  return (
    <>
      <section className="grow-section">
        <Container>
          <Row>
            <Col>
              <h2>
                <Trans
                  i18nKey="home:grow.title"
                  components={[<span className="font-sansation">univers</span>]}
                />
              </h2>
              <h3 id="grow" className="large_txt">{t('grow.extra_title')}</h3>
            </Col>
          </Row>
          <Row className="bg-img">
            <Col sm="12">
              <h3>{t('grow.sub_title')}</h3>
              <p>
                <Trans i18nKey="home:grow.desc" />
              </p>
              <div className="login-btns">
                <a href="https://app.saieve.io/account/register" className="start-earn">
                  {t('button.get_started')}
                <img src={Vector} alt="Vector"/>
                </a>
              </div>
            </Col>
            <Col sm="4">
              <div className="centred-block">
                <h3>{t('grow.save_time')}</h3>
                <ul>
                  <li><img src={check} alt="check"/> {t('grow.save_time_one')}</li>
                  <li><img src={check} alt="check"/> {t('grow.save_time_tow')}</li>
                  <li><img src={check} alt="check"/> {t('grow.save_time_three')}</li>
                </ul>
              </div>
            </Col>
            <Col sm="4">
              <div className="centred-block">
                <h3>{t('grow.automated_strategies')}</h3>
                <ul>
                  <li><img src={check} alt="check"/> {t('grow.automated_strategies_one')}</li>
                  <li><img src={check} alt="check"/> {t('grow.automated_strategies_tow')}</li>
                  <li><img src={check} alt="check"/> {t('grow.automated_strategies_three')}</li>
                </ul>
              </div>
            </Col>
            <Col sm="4">
              <div className="centred-block">
                <h3>{t('grow.stay_in_control')}</h3>
                <ul>
                  <li><img src={check} alt="check"/> {t('grow.stay_in_control_one')}</li>
                  <li><img src={check} alt="check"/> {t('grow.stay_in_control_tow')}</li>
                  <li><img src={check} alt="check"/> {t('grow.stay_in_control_three')}</li>
                </ul>
              </div>
            </Col>
            <Col sm="12">
              <Row className="circle_sec">
                <video
                  style={{marginTop: '-100px'}}
                  autoPlay
                  loop
                  muted
                  src={video.default}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default Grow;
 
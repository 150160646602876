import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { Container } from 'reactstrap';

const SixthPart: React.FC = () => {
  return (
    <StyledContainer>
      <Container fixed maxWidth={'lg'}>
        <WrapperBox>
          <Box position={'relative'}>
            <NotificationBox>
              <NotificationWrapper>
                <NotificationTitle>
                  SAIΞVE<span>now</span>
                </NotificationTitle>
                <NotificationDec>
                  We automatically invested your $500 deposit in the DeFi
                  Leaders tokens
                </NotificationDec>
              </NotificationWrapper>
            </NotificationBox>
            <ImgBox>
              <img src="/images/invest/tokenCap.png" alt="tokenCap" />
            </ImgBox>
            <NotificationBox
              position={'absolute'}
              marginTop={'-50px'}
              right={'21px'}
            >
              <NotificationWrapper>
                <NotificationTitle>
                  SAIΞVE<span>now</span>
                </NotificationTitle>
                <NotificationDec>
                  The Metaverse sector is up to 20% today
                </NotificationDec>
              </NotificationWrapper>
            </NotificationBox>
          </Box>
          <Box>
            <Title>
              Automation
              <br />
              That feels like magic
            </Title>
            <Desc>
              There’s no need to ever make a manual trade. We rebalance your
              portfolio, automatically diversify deposits, all without you ever
              lifting a finger. Basket constituents are updated and rebalanced
              automatically according to the market dynamics.
            </Desc>
          </Box>
        </WrapperBox>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding: 90px 120px 160px 120px;
  @media (max-width: 800px) {
    padding: 40px 0px;
  }
`;

const Desc = styled(Box)`
  margin-top: 25px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #7d91ae;
`;

const Title = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  color: #022b69;
`;

const ImgBox = styled(Box)``;

const WrapperBox = styled(Box)`
  display: flex;
  align-items: center;
  grid-column-gap: 32px;

  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
    gap: 200px;
  }
`;

const NotificationBox = styled(Box)`
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 54.3813px rgba(0, 23, 143, 0.2);
  backdrop-filter: blur(8px);
  border-radius: 10px;
  width: 385px;
  @media(max-width: 600px){
    width: 100%;
    right: 0 !important
  }
`;

const NotificationWrapper = styled(Box)`
  padding: 22px;
`;

const NotificationTitle = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 17.2603px;
  line-height: 25px;
  color: #0220bf;

  > span {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #022b69;
    opacity: 0.3;
  }

  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
  }
`;

const NotificationDec = styled(Box)`
  margin-top: 16px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #022b69;
`;

export default SixthPart;

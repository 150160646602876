import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Trans, useTranslation} from "react-i18next";
import ConfigurationAPI from '../../models/Configuration'
import support from '../../images/usdt.png';
import btc from '../../images/bitcoin.svg';
import eth from '../../images/ETH.svg';
import DropdownIcon from '../../images/Icon.png';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {months} from '../../constants/months'

const numeral = require('numeral');

const Calculate = (props: { getApy1yPercent: (arg0: any) => void, getDate: (date:string) => void; }) => {

  const { t, i18n } = useTranslation('home')
  const url = useHistory();
  const [isMobile, setIsMobile] = useState(false);
  const [value, setValue] = useState(10000);
  const [totalValue, setTotalValue] = useState(0);
  const [membershipLevel, setMembershipLevel] = useState("basic");
  const [projectedReturnValue, setProjectedReturnValue] = useState({
    basic: 0,
    bronze: 0,
    silver: 0,
    gold: 0,
  } as any);
  const [apy1yBonus, setApy1yBonus] = useState({
    basic: 0,
    bronze: 0,
    silver: 0,
    gold: 0,
  } as any);
  const [show, setShow] = useState(false);
  const [year, setYear] = useState(1);
  const [currency] = useState('USDT');
  const [apy1y, setApy1y] = useState(50);
  const [apy90d, setApy90d] = useState(0.078233);
  const [apy90dDate, setApy90dDate] = useState('01/06/2021');
  const [width, setWidth] = useState(600);
  const [svgHeight, setSvgHeight] = useState(300);
  const [height, setHeight] = useState(276);
  const [graphWidth, setGraphWidth] = useState(0);
  const [strokeLine1] = useState('white');
  const [strokeLine2, setStrokeLine2] = useState('#BFBFC6');
  const [path, setPath] = useState("M0.21329,275.38563L15.78322,274.25452L31.35315,272.77897L46.92308,271.01864L62.49301,269.00707L78.06294,266.76623L93.63287,264.31174L109.2028,261.65536L124.77273,258.80617L140.34266,255.77144L155.91259,252.55707L171.48252,249.1679L187.05245,245.60798L202.62238,241.88069L218.19231,237.98887L233.76224,233.93496L249.33217,229.72097L264.9021,225.34865L280.47203,220.81946L296.04196,216.13462L311.61189,211.29515L327.18182,206.30189L342.75175,201.15553L358.32168,195.85661L373.89161,190.40553L389.46154,184.8026L405.03147,179.04801L420.6014,173.14185L436.17133,167.08413L451.74126,160.87478L467.31119,154.51365L482.88112,148.00054L498.45105,141.33515L514.02098,134.51714L529.59091,127.54613L545.16084,120.42166L560.73077,113.14323L576.3007,105.7103L591.87063,98.12228L607.44056,90.37852L623.01049,82.47836L638.58042,74.42108L654.15035,66.20594L669.72028,57.83214L685.29021,49.29886L700.86014,40.60526L716.43007,31.75044L732,22.73348L732,276L716.43007,276L700.86014,276L685.29021,276L669.72028,276L654.15035,276L638.58042,276L623.01049,276L607.44056,276L591.87063,276L576.3007,276L560.73077,276L545.16084,276L529.59091,276L514.02098,276L498.45105,276L482.88112,276L467.31119,276L451.74126,276L436.17133,276L420.6014,276L405.03147,276L389.46154,276L373.89161,276L358.32168,276L342.75175,276L327.18182,276L311.61189,276L296.04196,276L280.47203,276L264.9021,276L249.33217,276L233.76224,276L218.19231,276L202.62238,276L187.05245,276L171.48252,276L155.91259,276L140.34266,276L124.77273,276L109.2028,276L93.63287,276L78.06294,276L62.49301,276L46.92308,276L31.35315,276L15.78322,276L0.21329,276Z");

  useEffect(() => {
    if(url.location.hash.includes('#calculator') || url.location.hash.includes('#crypto-calculator')){
      isMobile ? window.location.href = "#crypto-calculator": window.location.href = "#calculator";
    }
  }, [url, isMobile])

  useEffect(() => {
    ConfigurationAPI.getSaieveYield()
      .then(response => {
        setApy90d(response.data.yield_saieve)
        setApy90dDate(response.data.yield_saieve_date)

      })
      .catch(err => {
        console.log(err);
      });

    const clientWidth = window.innerWidth || document.documentElement.clientWidth;
    if (clientWidth < 768) {
      setIsMobile(true)
      setSvgHeight(200)
      setHeight(176)
      setPath("M0.08013,175.6377L5.92949,174.97029L11.77885,174.0991L17.62821,173.05912L23.47756,171.86996L29.32692,170.54443L35.17628,169.09161L41.02564,167.5183L46.875,165.82973L52.72436,164.03006L58.57372,162.12266L64.42308,160.11027L70.27244,157.99515L76.12179,155.77919L81.97115,153.46396L87.82051,151.05076L93.66987,148.54069L99.51923,145.93467L105.36859,143.23343L111.21795,140.43759L117.06731,137.54764L122.91667,134.56396L128.76603,131.48685L134.61538,128.3165L140.46474,125.05306L146.3141,121.69657L152.16346,118.24703L158.01282,114.70439L163.86218,111.06853L169.71154,107.33929L175.5609,103.51646L181.41026,99.5998L187.25962,95.58901L193.10897,91.48378L198.95833,87.28375L204.80769,82.98852L210.65705,78.59767L216.50641,74.11075L222.35577,69.52728L228.20513,64.84675L234.05449,60.06864L239.90385,55.19239L245.75321,50.21742L251.60256,45.14312L257.45192,39.96887L263.30128,34.69403L269.15064,29.31794L275,23.83989L275,176L269.15064,176L263.30128,176L257.45192,176L251.60256,176L245.75321,176L239.90385,176L234.05449,176L228.20513,176L222.35577,176L216.50641,176L210.65705,176L204.80769,176L198.95833,176L193.10897,176L187.25962,176L181.41026,176L175.5609,176L169.71154,176L163.86218,176L158.01282,176L152.16346,176L146.3141,176L140.46474,176L134.61538,176L128.76603,176L122.91667,176L117.06731,176L111.21795,176L105.36859,176L99.51923,176L93.66987,176L87.82051,176L81.97115,176L76.12179,176L70.27244,176L64.42308,176L58.57372,176L52.72436,176L46.875,176L41.02564,176L35.17628,176L29.32692,176L23.47756,176L17.62821,176L11.77885,176L5.92949,176L0.08013,176Z")
    } else {
      setIsMobile(false)
      setSvgHeight(300)
      setHeight(276)
      setPath("M0.21329,275.38563L15.78322,274.25452L31.35315,272.77897L46.92308,271.01864L62.49301,269.00707L78.06294,266.76623L93.63287,264.31174L109.2028,261.65536L124.77273,258.80617L140.34266,255.77144L155.91259,252.55707L171.48252,249.1679L187.05245,245.60798L202.62238,241.88069L218.19231,237.98887L233.76224,233.93496L249.33217,229.72097L264.9021,225.34865L280.47203,220.81946L296.04196,216.13462L311.61189,211.29515L327.18182,206.30189L342.75175,201.15553L358.32168,195.85661L373.89161,190.40553L389.46154,184.8026L405.03147,179.04801L420.6014,173.14185L436.17133,167.08413L451.74126,160.87478L467.31119,154.51365L482.88112,148.00054L498.45105,141.33515L514.02098,134.51714L529.59091,127.54613L545.16084,120.42166L560.73077,113.14323L576.3007,105.7103L591.87063,98.12228L607.44056,90.37852L623.01049,82.47836L638.58042,74.42108L654.15035,66.20594L669.72028,57.83214L685.29021,49.29886L700.86014,40.60526L716.43007,31.75044L732,22.73348L732,276L716.43007,276L700.86014,276L685.29021,276L669.72028,276L654.15035,276L638.58042,276L623.01049,276L607.44056,276L591.87063,276L576.3007,276L560.73077,276L545.16084,276L529.59091,276L514.02098,276L498.45105,276L482.88112,276L467.31119,276L451.74126,276L436.17133,276L420.6014,276L405.03147,276L389.46154,276L373.89161,276L358.32168,276L342.75175,276L327.18182,276L311.61189,276L296.04196,276L280.47203,276L264.9021,276L249.33217,276L233.76224,276L218.19231,276L202.62238,276L187.05245,276L171.48252,276L155.91259,276L140.34266,276L124.77273,276L109.2028,276L93.63287,276L78.06294,276L62.49301,276L46.92308,276L31.35315,276L15.78322,276L0.21329,276Z")
    }
  }, [])

  useEffect(() => {
    props.getDate(apy90dDate)
  }, [props, apy90dDate])

  const monthDiff = (d1: Date, d2: Date) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }
  const [day, month, yr] = apy90dDate.split('/')
  const monthAgo = monthDiff(new Date(2021, 0, 1), new Date(Number(yr), Number(month), Number(day)))
  useEffect(() => {
    if (!value) {
      setTotalValue(0);
    } else {
      let ppr = (1+apy1y)*value;
      const burnValue = 0.25;
      const performanceFees = 0.25;
      const bonusGold = ((1-burnValue)*performanceFees)*apy1y*value
      setProjectedReturnValue(
        {
          basic: ppr,
          bronze: ppr+(bonusGold*0.33),
          silver: ppr+(bonusGold*0.66),
          gold: ppr+bonusGold,
        }
      )
    }
    if (isNaN(value)) {
      setValue(0)
    }
  }, [value, apy1y])
  
  useEffect(() => {
    setApy1yBonus({
      basic: apy1y,
      bronze: parseFloat(((projectedReturnValue.bronze/value)-1).toFixed(4)),
      silver: parseFloat(((projectedReturnValue.silver/value)-1).toFixed(4)),
      gold: parseFloat(((projectedReturnValue.gold/value)-1).toFixed(4)),
    })
  }, [value, apy1y, projectedReturnValue])

  useEffect(() => {

    setApy1y((Math.pow(1+(apy90d/100), (12/monthAgo))-1));

    const graphParent = document.getElementById('graphParent');

    props.getApy1yPercent((apy1yBonus[membershipLevel]*100).toFixed(1));

    // @ts-ignore
    setWidth(graphParent.clientWidth-60);

    if (year === 3) {
      setTotalValue(
        Math.pow(1+apy1yBonus[membershipLevel], 3)*value
      );
      setGraphWidth(width);
    } else if (year === 2) {
      setTotalValue(
        Math.pow(1+apy1yBonus[membershipLevel], 2)*value
      );
      setGraphWidth(width*0.6666);
      setStrokeLine2('white')
    } else {
      setTotalValue(projectedReturnValue[membershipLevel]);
      setGraphWidth(width*0.3333);
      setStrokeLine2('#BFBFC6');
    }
  }, [year, apy1y, value, width, apy90d, props, monthAgo, membershipLevel, apy1yBonus, projectedReturnValue])

  const toggle = () => setShow(prevState => !prevState);

  // @ts-ignore
  return (
    <>
      <section className="calculator-section">
        <Container>
          <Row>
            <Col sm="12" className="title-sec" style={{paddingTop: 50}}>
              <h2 className="feature-title">
                <Trans
                  i18nKey="home:calculate.title"
                  components={[<span>univers</span>]}
                />
              </h2>
              <h3 id="calculator" className="large_txt">{t('calculate.extra_title')}</h3>
              <h3>
                <Trans
                  i18nKey="home:calculate.sub_title"
                />
              </h3>
              <p>
                {t('calculate.description')}
              </p>
            </Col>
            <Col id="crypto-calculator" md="6" className="graph-form">
              <Form style={{paddingRight: 30, borderRadius: 10}}>
                <Label for="usd">{t('calculate.crypto')}</Label>
                <Dropdown isOpen={show} toggle={toggle} style={{width: '100%'}}>
                  <DropdownToggle style={{
                    width: '100%',
                    borderRadius: 100,
                    backgroundColor: 'white',
                    color: '#000',
                    borderColor: '#ced8f8',
                    borderWidth: 3,
                    textAlign: 'left',
                    height: 60
                  }}>
                    <Row style={{width: '100%'}}>
                      <Col xs="9">
                        {currency === 'BTC' ? <img className="dropdown-img" style={{marginBottom: -17}} src={btc} alt="support"/> : currency === "ETH" ?
                          <img
                            className="dropdown-img"
                            src={eth} alt="support"
                            style={{marginBottom: -17}}
                          /> : <img className="dropdown-img usdt" style={{marginBottom: -17}} src={support} alt="support"/>}
                        <span>
                          <span style={{}}>{currency}</span>
                          <span
                            className="simulator"
                            style={{
                              marginTop: -6,
                              marginLeft: 47
                            }}
                          >
                            {(apy1yBonus[membershipLevel]*100).toFixed(1)}% {t('calculate.apy')}*
                          </span>
                        </span>
                      </Col>
                      <Col xs="3" style={{alignSelf: 'center', textAlign: 'right'}}>
                        <img className="dropdown-img-arrow" src={DropdownIcon} alt="DropdownIcon"/>
                      </Col>
                    </Row>
                  </DropdownToggle>
                  <DropdownMenu style={{width: '100%', top: '2px!important'}}>
                    <DropdownItem>
                      <img className="dropdown-img usdt" src={support} alt="USDT"/>
                      <strong>USDT</strong>
                      <span className="simulator first">{(apy1yBonus[membershipLevel]*100).toFixed(1)} {t('calculate.apy')}*</span>
                    </DropdownItem>
                    <DropdownItem>
                      <img className="dropdown-img" src={btc} alt="BTC"/>
                      <strong>BTC</strong>
                      <span className="simulator">{t('button.coming_soon')}</span>
                    </DropdownItem>
                    <DropdownItem>
                      <img className="dropdown-img" src={eth} alt="ETH"/>
                      <strong>ETH</strong>
                      <span className="simulator">{t('button.coming_soon')}</span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <span className="text-mob mb-4" style={{textAlign: 'justify'}}>{t('calculate.annual_percentage_yield', {date: months[i18n.language][parseInt(month)-1], day: day, yr: yr, monthAgo: monthAgo})}</span>
                <FormGroup className="mt-3">
                  <Label for="usd">{t('calculate.deposit_amount')}</Label>
                  <Input type="text" name="usd" id="usd" value={value} onChange={(value) => {
                    setValue(parseInt(value.target.value))
                  }} placeholder="$10,000" pattern="[0-9]*"/>
                  <span>{t('calculate.approx')}. 10,000 USDT</span>
                </FormGroup>
                <FormGroup className="mt-3">
                  <Label for="usd" className="mb-0">{t('calculate.membership_level')}</Label>
                  <div className="switch-field">
                    <input
                      type="radio" id="radio-basic" name="switch-two"
                      value="basic"
                      checked={membershipLevel==='basic'}
                      onChange={(e) => setMembershipLevel(e.target.value)}
                    />
                    <label htmlFor="radio-basic"><p>{t('earn_pcl.basic')}</p></label>
                    <input
                      type="radio" id="radio-bronze" name="switch-two"
                      value="bronze"
                      checked={membershipLevel==='bronze'}
                      onChange={(e) => setMembershipLevel(e.target.value)}
                    />
                    <label htmlFor="radio-bronze"><p>{t('earn_pcl.bronze')}</p></label>
                    <input
                      type="radio" id="radio-silver" name="switch-two"
                      value="silver"
                      checked={membershipLevel==='silver'}
                      onChange={(e) => setMembershipLevel(e.target.value)}
                    />
                    <label htmlFor="radio-silver"><p>{t('earn_pcl.silver')}</p></label>
                    <input
                      type="radio" id="radio-gold" name="switch-two"
                      value="gold"
                      checked={membershipLevel==='gold'}
                      onChange={(e) => setMembershipLevel(e.target.value)}
                    />
                    <label htmlFor="radio-gold"><p>{t('earn_pcl.gold')}</p></label>
                  </div>
                  <span className="pt-0"> <Trans i18nKey="home:calculate.membership_subtitle"/></span>
                </FormGroup>
                {
                  isMobile &&
                  <div style={{ marginBottom: 40}}>
                    <h4 className="graph-title" style={{ marginBottom: -20 }}>{t('calculate.investment_duration')}</h4>
                    <svg width={width} height={svgHeight}>
                      <defs>
                        <linearGradient id="area-gradient" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="0%" stopColor="#03d6dd" stopOpacity="1"/>
                          <stop offset="100%" stopColor="#4a55ca" stopOpacity="1"/>
                        </linearGradient>
                      </defs>
                      <clipPath id="baseSvgClip">
                        <path
                          className="vx-area-closed"
                          d={path}
                          strokeWidth="1" stroke="#F3F3F3" fill="#F3F3F3"
                        />
                      </clipPath>
                      <g clipPath="url(#baseSvgClip)">
                        <rect width={width} height={height} strokeWidth="1" stroke="#F3F3F3" fill="#F3F3F3"/>
                        <rect height={height} strokeWidth="1" stroke="url(#area-gradient)" fill="url(#area-gradient)"
                              width={graphWidth}/>
                        <line className="vx-line" x1={width*0.6666} y1="0" x2={width*0.6666} y2={height}
                              fill="transparent" stroke={strokeLine1}/>
                        <line className="vx-line" x1={width*0.3333} y1="0" x2={width*0.3333} y2={height}
                              fill="transparent" stroke={strokeLine2}/>
                      </g>
                    </svg>
                    <Row>
                      <ButtonToolbar style={{}}>
                        <ButtonGroup style={{width: '100%'}}>
                          <Button
                            className="btn-graph first"
                            style={{
                              backgroundColor: year === 1 ? '#000' : 'transparent',
                              color: year === 1 ? '#fff' : '#000'
                            }}
                            onClick={() => {
                              setYear(1)
                            }}
                          >{t('calculate.yr_one')}</Button>
                          <Button
                            className="btn-graph second"
                            style={{
                              backgroundColor: year === 2 ? '#000' : 'transparent',
                              color: year === 2 ? '#fff' : '#000'
                            }}
                            onClick={() => {
                              setYear(2)
                            }}
                          >{t('calculate.yr_two')}</Button>
                          <Button
                            className="btn-graph third"
                            style={{
                              backgroundColor: year === 3 ? '#000' : 'transparent',
                              color: year === 3 ? '#fff' : '#000'
                            }}
                            onClick={() => {
                              setYear(3)
                            }}
                          >{t('calculate.yr_three')}</Button>
                        </ButtonGroup>
                      </ButtonToolbar>
                    </Row>
                    <p className="text-mob mt-5">{t('calculate.simulator')}</p>
                  </div>
                }
              </Form>
            </Col>
            <Col id="graphParent" md="6" className="graph" style={{paddingBottom: '10px'}}>
              <div className="text-center">
                <span>{t('calculate.projected_potential')}</span>
                <h3>${numeral(totalValue).format('0,0.00')}</h3>
                <p
                  style={{
                    color: '#777',
                    fontSize: '15px',
                    marginTop: '16px'
                  }}
                >
                  <Trans i18nKey="home:calculate.projected_potential_desc"/>
                </p>
              </div>
              {
                !isMobile &&
                <>
                  <div>
                    <h4 className="graph-title" style={{marginTop: 60, marginBottom: -80, textAlign: 'center'}}>
                      {t('calculate.investment_duration')}
                    </h4>
                    <svg className="v-hide-on-mobile" width={width} height={svgHeight}>
                      <defs>
                        <linearGradient id="area-gradient" x1="0" y1="0" x2="0" y2="1">
                          <stop offset="0%" stopColor="#03d6dd" stopOpacity="1"/>
                          <stop offset="100%" stopColor="#4a55ca" stopOpacity="1"/>
                        </linearGradient>
                      </defs>
                      <clipPath id="baseSvgClip">
                        <path
                          className="vx-area-closed"
                          d={path}
                          strokeWidth="1" stroke="#F3F3F3" fill="#F3F3F3"
                        />
                      </clipPath>
                      <g clipPath="url(#baseSvgClip)">
                        <rect width={width} height={height} strokeWidth="1" stroke="#F3F3F3" fill="#F3F3F3"/>
                        <rect height={height} strokeWidth="1" stroke="url(#area-gradient)" fill="url(#area-gradient)"
                              width={graphWidth}/>
                        <line className="vx-line" x1={width*0.6666} y1="0" x2={width*0.6666} y2={height}
                              fill="transparent" stroke={strokeLine1}/>
                        <line className="vx-line" x1={width*0.3333} y1="0" x2={width*0.3333} y2={height}
                              fill="transparent" stroke={strokeLine2}/>
                      </g>
                    </svg>
                    <Row className="v-hide-on-mobile">
                      <ButtonToolbar style={{}}>
                        <ButtonGroup style={{width: '100%'}}>
                          <Button
                            className="btn-graph first"
                            style={{
                              backgroundColor: year === 1 ? '#000' : 'transparent',
                              color: year === 1 ? '#fff' : '#000'
                            }}
                            onClick={() => {
                              setYear(1)
                            }}
                          >{t('calculate.yr_one')}</Button>
                          <Button
                            className="btn-graph second"
                            style={{
                              backgroundColor: year === 2 ? '#000' : 'transparent',
                              color: year === 2 ? '#fff' : '#000'
                            }}
                            onClick={() => {
                              setYear(2)
                            }}
                          >{t('calculate.yr_two')}</Button>
                          <Button
                            className="btn-graph third"
                            style={{
                              backgroundColor: year === 3 ? '#000' : 'transparent',
                              color: year === 3 ? '#fff' : '#000'
                            }}
                            onClick={() => {
                              setYear(3)
                            }}
                          >{t('calculate.yr_three')}</Button>
                        </ButtonGroup>
                      </ButtonToolbar>
                    </Row>
                  </div>
                  <p className="text-mob mt-5">{t('calculate.simulator')}</p>
                </>
              }
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Calculate;

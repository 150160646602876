import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { Container } from 'reactstrap';

const FifthPart: React.FC = () => {
  return (
    <StyledContainer>
      <Container fixed maxWidth={'lg'}>
        <WrapperBox>
          <ItemBox>
            <MainContent>
              <Box
                height={'165px'}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <ItemIcon>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 11.9925H7V12.9925H7.5V11.9925ZM17.5 12.9925H18V11.9925H17.5V12.9925ZM7.5 17.0002H7V18.0002H7.5V17.0002ZM17.5 18.0002H18V17.0002H17.5V18.0002ZM7.5 6.99579H7V7.99579H7.5V6.99579ZM17.5 7.99579H18V6.99579H17.5V7.99579ZM22.5 5.8335H23V5.62639L22.8536 5.47994L22.5 5.8335ZM17.5 0.833496L17.8536 0.479943L17.7071 0.333496H17.5V0.833496ZM7.5 12.9925H17.5V11.9925H7.5V12.9925ZM7.5 18.0002H17.5V17.0002H7.5V18.0002ZM7.5 7.99579H17.5V6.99579H7.5V7.99579ZM20.8333 23.6668H4.16667V24.6668H20.8333V23.6668ZM3 22.5002V2.50016H2V22.5002H3ZM22 5.8335V22.5002H23V5.8335H22ZM4.16667 1.3335H17.5V0.333496H4.16667V1.3335ZM17.1464 1.18705L22.1464 6.18705L22.8536 5.47994L17.8536 0.479943L17.1464 1.18705ZM4.16667 23.6668C3.52233 23.6668 3 23.1445 3 22.5002H2C2 23.6968 2.97005 24.6668 4.16667 24.6668V23.6668ZM20.8333 24.6668C22.0299 24.6668 23 23.6968 23 22.5002H22C22 23.1445 21.4777 23.6668 20.8333 23.6668V24.6668ZM3 2.50016C3 1.85583 3.52233 1.3335 4.16667 1.3335V0.333496C2.97005 0.333496 2 1.30355 2 2.50016H3Z"
                      fill="#7D91AE"
                    />
                  </svg>
                </ItemIcon>
                <Box marginRight={'-42px'}>
                  <img src="./images/invest/explBack.png" alt="explBack" />
                </Box>
              </Box>
              <ItemTitle>Broad Exposure</ItemTitle>
              <ItemContent>
                There’s no need to open a position on each token individually.
                SAIEVE Baskets give exposure to multiple assets/coins.
              </ItemContent>
            </MainContent>
          </ItemBox>
          <ItemBox>
            <MainContent>
              <Box height={'165px'} display={'flex'} alignItems={'center'}>
                <ItemIcon>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.16667 4.16683V3.66683H3.66667V4.16683H4.16667ZM20.8333 4.16683H21.3333V3.66683H20.8333V4.16683ZM4.16667 14.1668H3.66667V14.6668H4.16667V14.1668ZM20.8333 14.1668V14.6668H21.3333V14.1668H20.8333ZM12.5 12.0002C10.9352 12.0002 9.66667 10.7316 9.66667 9.16683H8.66667C8.66667 11.2839 10.3829 13.0002 12.5 13.0002V12.0002ZM15.3333 9.16683C15.3333 10.7316 14.0648 12.0002 12.5 12.0002V13.0002C14.6171 13.0002 16.3333 11.2839 16.3333 9.16683H15.3333ZM12.5 6.3335C14.0648 6.3335 15.3333 7.60202 15.3333 9.16683H16.3333C16.3333 7.04974 14.6171 5.3335 12.5 5.3335V6.3335ZM12.5 5.3335C10.3829 5.3335 8.66667 7.04974 8.66667 9.16683H9.66667C9.66667 7.60202 10.9352 6.3335 12.5 6.3335V5.3335ZM2.5 1.3335H22.5V0.333496H2.5V1.3335ZM23.6667 2.50016V15.8335H24.6667V2.50016H23.6667ZM22.5 17.0002H2.5V18.0002H22.5V17.0002ZM1.33333 15.8335V2.50016H0.333333V15.8335H1.33333ZM2.5 17.0002C1.85567 17.0002 1.33333 16.4778 1.33333 15.8335H0.333333C0.333333 17.0301 1.30338 18.0002 2.5 18.0002V17.0002ZM23.6667 15.8335C23.6667 16.4778 23.1443 17.0002 22.5 17.0002V18.0002C23.6966 18.0002 24.6667 17.0301 24.6667 15.8335H23.6667ZM22.5 1.3335C23.1443 1.3335 23.6667 1.85583 23.6667 2.50016H24.6667C24.6667 1.30355 23.6966 0.333496 22.5 0.333496V1.3335ZM2.5 0.333496C1.30338 0.333496 0.333333 1.30355 0.333333 2.50016H1.33333C1.33333 1.85583 1.85567 1.3335 2.5 1.3335V0.333496ZM0 21.3335H25V20.3335H0V21.3335ZM0 24.6668H25V23.6668H0V24.6668ZM4.66667 6.66683V4.16683H3.66667V6.66683H4.66667ZM4.16667 4.66683H6.66667V3.66683H4.16667V4.66683ZM18.3333 4.66683H20.8333V3.66683H18.3333V4.66683ZM20.3333 4.16683V6.66683H21.3333V4.16683H20.3333ZM3.66667 11.6668V14.1668H4.66667V11.6668H3.66667ZM4.16667 14.6668H6.66667V13.6668H4.16667V14.6668ZM18.3333 14.6668H20.8333V13.6668H18.3333V14.6668ZM21.3333 14.1668V11.6668H20.3333V14.1668H21.3333Z"
                      fill="#7D91AE"
                    />
                  </svg>
                </ItemIcon>
              </Box>
              <ItemTitle>Easy Diversification</ItemTitle>
              <ItemContent>
                One poorly perfoming crypto is less likely to negatively impact
                your whole portfolio.
              </ItemContent>
            </MainContent>
            <BackImg>
              <img src="./images/invest/divBack.png" alt="divBack" />
            </BackImg>
          </ItemBox>
          <ItemBox>
            <MainContent>
              <Box height={'165px'} display={'flex'} alignItems={'center'}>
                <ItemIcon>
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.833984 24.1667H0.333984V24.6667H0.833984V24.1667ZM3.66732 19.1667V19.6667H4.66732V19.1667H3.66732ZM24.6673 4.16667V3.66667H23.6673V4.16667H24.6673ZM0.333984 0V24.1667H1.33398V0H0.333984ZM0.833984 24.6667H25.0007V23.6667H0.833984V24.6667ZM4.66732 19.1667C4.66732 16.7123 5.07777 14.2868 5.87422 12.4947C6.67822 10.6857 7.79879 9.66667 9.16732 9.66667V8.66667C7.20251 8.66667 5.82308 10.1476 4.96041 12.0886C4.0902 14.0466 3.66732 16.6211 3.66732 19.1667H4.66732ZM9.16732 9.66667C9.82141 9.66667 10.4342 9.99094 11.0678 10.5757C11.7048 11.1638 12.3018 11.9571 12.934 12.8C13.5518 13.6238 14.2048 14.4971 14.9219 15.1591C15.6426 15.8243 16.4881 16.3333 17.5007 16.3333V15.3333C16.8466 15.3333 16.2337 15.0091 15.6002 14.4243C14.9632 13.8362 14.3661 13.0429 13.734 12.2C13.1161 11.3762 12.4632 10.5029 11.746 9.84093C11.0254 9.17573 10.1799 8.66667 9.16732 8.66667V9.66667ZM17.5007 16.3333C19.3905 16.3333 21.1966 15.1539 22.5067 13.0795C23.8192 11.0014 24.6673 7.98576 24.6673 4.16667H23.6673C23.6673 7.84757 22.8487 10.6653 21.6612 12.5455C20.4714 14.4294 18.9442 15.3333 17.5007 15.3333V16.3333Z"
                      fill="#7D91AE"
                    />
                  </svg>
                </ItemIcon>
              </Box>
              <ItemTitle>Track the Market</ItemTitle>
              <ItemContent>
                SAIEVE Baskets make it possible to observe and compare the
                performance of different crypto sectors.
              </ItemContent>
            </MainContent>
            <BackImg3>
              <img src="./images/invest/trackBack.png" alt="track" />
            </BackImg3>
          </ItemBox>
        </WrapperBox>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding-top: 135px;
`;

const BackImg = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const BackImg3 = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
`;

const ItemTitle = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #022b69;
`;

const ItemContent = styled(Box)`
  margin-top: 13px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #7d91ae;
`;

const ItemIcon = styled(Box)`
  width: 78px;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(
    92.61deg,
    rgba(0, 11, 115, 0.1) 0%,
    rgba(0, 15, 146, 0.15) 100%
  );
  border-radius: 8px;
`;

const WrapperBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 22px;

  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MainContent = styled(Box)`
  padding: 0px 42px 42px 42px;
`;

const ItemBox = styled(Box)`
  position: relative;
  width: 381px;
  height: 381px;
  background: #ffffff;
  box-shadow: 0px 0px 54.3813px rgba(0, 23, 143, 0.2);
  border-radius: 10px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export default FifthPart;

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from '../app/routes'

const Routes = () => {
  return (
    <Switch>
      {
        routes.map((route, index) => {
          return <Route key={index} exact path={route.path} component={route.component} />
        })
      }
      <Redirect to="/" />
    </Switch>
  )
}

export default Routes;
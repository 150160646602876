import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import Investing from './Investing';
import { Container, Row, Col } from 'reactstrap';
import logo from '../images/footer_logo.svg';
import logoPcl from '../images/logo_pcl_white.svg';
import twitter from '../images/icons/twitter.svg'
import facebook from '../images/icons/facebook.svg'
import youtube from '../images/icons/youtube.svg'
import instagram from '../images/icons/instagram.svg'
import telegram from '../images/icons/telegram.svg'
import linkedin from '../images/icons/linkedin.svg'

const Footer = () => {
  const { t } = useTranslation('home')

  return (
    <>
      <footer id="wait-list" className="footer-section">
        <Container fluid>
          <Row className="top_row">
            <Col sm="6">
              <Investing/>
            </Col>
            <Col sm="6">
              <div className="footer-detail">
                <Row>
                  <Col sm="12">
                    <a href="/#"><img className="footer-logo" src={logo} alt="logo"/></a>
                  </Col>
                  <Col xs="6" className="mb-4">
                    <h3>{t('footer.menu.earn.earn')}</h3>
                    <ul>
                      <li>
                        <a href="/#earn_sec">
                          <Trans
                            i18nKey="home:footer.menu.earn.believe_account"
                            components={[<span className="font-sansation">univers</span>]}
                          />
                        </a>
                      </li>
                      <li><a href="/#earn_sec">{t('footer.menu.earn.smart_holding_accounts')}</a></li>
                      <li><a href="/#calculator">{t('footer.menu.earn.earnings_calculator')}</a></li>
                    </ul>
                  </Col>
                  <Col xs="6" className="mb-4">
                    <h3>{t('footer.menu.how_it_works.how_it_works')}</h3>
                    <ul>
                      <li><a href="/#grow">{t('footer.menu.how_it_works.our_approach')}</a></li>
                      <li><a href="/#visual-section">{t('footer.menu.how_it_works.predicted_coins')}</a></li>
                    </ul>
                  </Col>
                  <Col xs="6" className="mb-4">
                    <h3>{t('footer.menu.features.features')}</h3>
                    <ul>
                      <li><a href="/#feature_sec">{t('footer.menu.features.savings')}</a></li>
                      <li><a href="/#feature_sec">{t('footer.menu.features.sweetpot')}</a></li>
                      <li><a href="/#feature_sec">{t('footer.menu.features.pcl_balance')}</a></li>
                      <li><a href="/#feature_sec">{t('footer.menu.features.p_l_analysis')}</a></li>
                      <li><a href="/#feature_sec">{t('footer.menu.features.portfolio_allocation')}</a></li>
                    </ul>

                  </Col>
                  <Col xs="6" className="mb-4">
                    <h3>{t('footer.menu.pcl_token.pcl_token')}</h3>
                    <ul>
                      <li><a href="/#membership">{t('footer.menu.pcl_token.membership_levels')}</a></li>
                    </ul>
                    <h3 className="mt-4">{t('footer.menu.community.community')}</h3>
                    <ul>
                      <li><a target="_blank" rel="noreferrer" href="https://peculium.io/blog">{t('footer.menu.community.blog')}</a></li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="copyright">
            <Col sm="12" className="mb-3 mt-3">
              <ul className="social-footer">
                <li>
                  <a target="_blank" rel="noreferrer" href="https://twitter.com/_Peculium">
                    <img src={twitter} alt="Twitter"/>
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/PeculiumICO">
                    <img src={facebook} alt="Facebook"/>
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCBhRs-vzAuv_ezlPBcZjmNQ">
                    <img src={youtube} alt="Youtube"/>
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/peculium_crypto">
                    <img src={instagram} alt="Instagram"/>
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://t.me/joinchat/WIsY1wGKYJE7MiC7">
                    <img src={telegram} alt="Telegram"/>
                  </a>
                </li>
                <li>
                  <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/peculium.io">
                    <img src={linkedin} alt="Linkedin"/>
                  </a>
                </li>
              </ul>
            </Col>
            <Col sm="6">
              <p>© Copyright 2021 <span className="font-sansation">SAIΞVE</span>. {t('footer.copyright')}</p>
            </Col>
            <Col sm="6" className="text-center">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://peculium.io"
                style={{
                  color: '#fff',
                  textDecoration: 'none'
                }}
              >
                <img
                  src={logoPcl} alt="visit"
                  style={{
                    width: 24,
                    marginRight: 6,
                    marginTop: -2
                  }}
                />
                {t('footer.peculium_link')}
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}
export default Footer;

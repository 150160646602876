import en from "../images/icons/en.svg";
import fr from "../images/icons/fr.svg";

export type Language = {
  name: string,
  code: string,
  iso: string,
  flag : string
}

export const languages = [
  {name: "English", code: "en-US", iso: "en", flag : en },
  {name: "Français", code: "fr-FR", iso: "fr", flag : fr },
]
import React from 'react';
import faq from '../../images/Union.png';
import {Container, Row, Col} from 'reactstrap';
import {Trans, useTranslation} from "react-i18next";
import Faqdetail from './Faq_detail';

const Faq = () => {
  const { t } = useTranslation('home')

  return (<>
    <section className="faq-section" id="faq">
      <Container>
        <Row>
          <Col sm="12" className="title-sec">
            <img src={faq} alt="faq"/>
            <h3>
              <Trans t={t} i18nKey="home:faq.title">
                Got questions?
              </Trans>
            </h3>
            <p>
              <Trans i18nKey="home:faq.sub_title">
                Get answers and information about growing your savings at <span className="font-sansation">SAIΞVE</span>
              </Trans>
            </p>
          </Col>
          <Faqdetail/>
          <h4 className="find">
            <Trans i18nKey="home:faq.link">
              Didn’t find your answer?
              <a target="_blank" rel="noreferrer" href="https://help.peculium.io/">Visit our Help Center →</a>
            </Trans>
          </h4>
        </Row>

      </Container>
    </section>
  </>)
}
export default Faq;
 
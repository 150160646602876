import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import Image1 from '../../images/sweet.svg';
import Image2 from '../../images/profit.svg';
import Image3 from '../../images/portfolio.svg';
import Image4 from '../../images/balance.svg';
import Image5 from '../../images/saving.svg';

function Slider() {
  const { t } = useTranslation('home')

  return (
    <Carousel>
      <div className="slider-cont">

        <img src={Image5} alt="" className="slider-img"/>

        <div className="slider-txt">
          <h3>{t('feature.slider.s1_title')}</h3>
          <p>
            <Trans
              i18nKey="home:feature.slider.s1_desc"
              components={[<span className="font-sansation">univers</span>]}
            />
          </p>
        </div>
      </div>
      <div className="slider-cont">

        <img src={Image1} alt="" className="slider-img"/>

        <div className="slider-txt">
          <h3>{t('feature.slider.s2_title')}</h3>
          <p>
            <Trans
              i18nKey="home:feature.slider.s2_desc1"
              components={[<span className="font-sansation">univers</span>]}
            />
          </p>
          <p>
            <Trans
              i18nKey="home:feature.slider.s2_desc2"
              components={[<span className="font-sansation">univers</span>]}
            />
          </p>
        </div>
      </div>
      <div className="slider-cont">

        <img src={Image4} alt="Image4" className="slider-img"/>

        <div className="slider-txt">
          <h3>{t('feature.slider.s3_title')}</h3>
          <p>
            {t('feature.slider.s3_desc')}
          </p>
        </div>
      </div>
      <div className="slider-cont">

        <img src={Image2} alt="" className="slider-img"/>

        <div className="slider-txt">
          <h3>{t('feature.slider.s4_title')}</h3>
          <p>{t('feature.slider.s4_desc')}</p>
        </div>
      </div>
      <div className="slider-cont">

        <img src={Image3} alt="Image3" className="slider-img"/>

        <div className="slider-txt">
          <h3>{t('feature.slider.s5_title')}</h3>
          <p>
            <Trans i18nKey="home:feature.slider.s5_desc"/>
          </p>
        </div>
      </div>
    </Carousel>
  );
}

export default Slider;


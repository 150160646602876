import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { Container } from 'reactstrap';
import IntroPart from './IntroPart';
import SecondPart from './SecondPart';
import FourthPart from './FourthPart';
import FifthPart from './FifthPart';
import SixthPart from './SixthPart';
import LastPart from './LastPart';

const Investing: React.FC = () => {
  return (
    <StyledContainer>
      <Container>
        <IntroPart />
      </Container>
      <SecondPart />
      <FourthPart />
      <FifthPart />
      <SixthPart />
      <LastPart />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  position: relative;
`;

export default Investing;

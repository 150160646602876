import React from 'react';
import {useTranslation, Trans} from "react-i18next";
import line from '../../images/line.png';
import create from '../../images/create.svg';
import link from '../../images/link.svg';
import onboard from '../../images/onboard.svg';
import grow from '../../images/grow.svg';
import arrow from '../../images/arrow.png';
import bg from '../../images/bg.png';
import funds from '../../images/funds.svg';
import bottom from '../../images/bottom.png';
import bg1 from '../../images/bg1.png';
import Vector from '../../images/square.svg';
import binance from '../../images/binance.svg';
import huobi from '../../images/huobi.svg';
import {Container, Row, Col} from 'reactstrap';


const How_works = () => {
  const { t } = useTranslation('home')

  return (
    <>
      <section className="How_works-section">
        <Container>

          <Row>
            <Col sm="12">
              <img className="line" src={line} alt="line"/>
              <h2>{t('how_it_works.title')}</h2>
              <h3 id="howitworks" className="large_txt">{t('how_it_works.extra_title')}</h3>
            </Col>
            <Col sm="3" className="works">
              <img className="arrow" src={arrow} alt="arrow"/>
              <img src={create} alt="create"/>
              <h3>{t('how_it_works.create')}</h3>
              <h4>
                <Trans
                  i18nKey="home:how_it_works.create_title"
                  components={[<span className="font-sansation">univers</span>]}
                />
              </h4>
              <p>{t('how_it_works.create_desc')}</p>
            </Col>
            <Col sm="3" className="works">
              <img className="arrow" src={arrow} alt="arrow"/>
              <img src={link} alt="link"/>
              <h3>{t('how_it_works.link')}</h3>
              <h4>{t('how_it_works.link_title')}</h4>
              <p>
                <Trans
                  i18nKey="home:how_it_works.link_desc"
                  components={[<span className="font-sansation">univers</span>]}
                />
              </p>
            </Col>
            <Col sm="3" className="works">
              <img className="arrow" src={arrow} alt="works"/>
              <img src={onboard} alt="onboard"/>
              <h3>{t('how_it_works.onboard')}</h3>
              <h4>{t('how_it_works.onboard_title')}</h4>
              <p>
                <Trans
                  i18nKey="home:how_it_works.onboard_desc"
                  components={[<span className="font-sansation">univers</span>]}
                />
              </p>
            </Col>
            <Col sm="3" className="works">
              <img src={grow} alt="grow"/>
              <h3>{t('how_it_works.grow')}</h3>
              <h4>{t('how_it_works.grow_title')}</h4>
              <p>
                <Trans
                  i18nKey="home:how_it_works.grow_desc"
                  components={[<span className="font-sansation">univers</span>]}
                />
              </p>
            </Col>
            <Col sm="12" className="saieve" style={{backgroundImage: `url(${bg})`}}>
              <h3>
                <Trans
                  i18nKey="home:how_it_works.how_title"
                  components={[<span className="font-sansation">univers</span>, <span></span>]}
                />
              </h3>
              <p>
                <Trans
                  i18nKey="home:how_it_works.how_desc"
                  components={[<span className="font-sansation">univers</span>]}
                />
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="fund-section" style={{backgroundImage: `url(${bg1})`}}>
        <Container>
          <Row className="fund-sec" style={{maxWidth: 1320}}>
            <Col sm="4" className="saieve-bottom">
              <h3>{t('how_it_works.fund_title')}</h3>
              <p>
                <Trans
                  i18nKey="home:how_it_works.fund_desc"
                  components={[<span className="font-sansation">univers</span>]}
                />
              </p>
              <div className="login-btns">
                <a href="https://app.saieve.io/account/login" className="log">{t('button.login')}</a>
                <a href="https://app.saieve.io/account/register" className="start-earn">{t('button.get_started')} <img src={Vector}
                                                                                                         alt="vector"/></a>
              </div>
              <div className="exchanges">
                <p>
                  <Trans
                    i18nKey="home:how_it_works.exchanges"
                    components={[<span className="font-sansation">univers</span>]}
                  />
                </p>
                <div className="soon-box">
                  <img className="exchanges" src={binance} alt="BINANCE"/>
                  <img className="exchanges" src={huobi} alt="HUOBI"/>
                  <span className="badge">Coming soon</span>
                </div>
                <p>
                  <Trans
                    i18nKey="home:how_it_works.more_exchanges"
                    components={[<span className="font-sansation">univers</span>]}
                  />
                </p>
              </div>
              <img className="fund-img" src={bottom} alt="bottom"/>
            </Col>
            <Col sm="8" className="funds">
              <img src={funds} alt="funds"/>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default How_works;

import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import i18n from '../i18n'
import Logo from '.././images/logo.svg';
import Vector from '.././images/square.svg';
import {Language, languages} from "../constants/languages";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState(languages[0]);
  const { t } = useTranslation();

  const toggle = () => setIsOpen(!isOpen);
  const changeLang = (lang: Language) => {
    setSelectedLang(lang)
    localStorage.setItem('i18nlanguage', lang.iso)
    i18n.changeLanguage(lang.iso)
  };

  useEffect(() => {
    let lang = i18n.language.split('-')[0];
    if(['en', 'fr'].includes(lang)){
      let selected = languages.find(element => element.iso === lang) || languages[0]
      setSelectedLang(selected)
    }
  }, [])

  return (
    <>
      <Navbar color="" light expand="lg">
        <NavbarBrand href="/"><img src={Logo} alt="Logo"/></NavbarBrand>
        <NavbarToggler onClick={toggle}/>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink href="#earn_sec">{ t('menu.earn') }</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#howitworks">{ t('menu.how_it_works') }</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#feature_sec">{ t('menu.features') }</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#membership">{ t('menu.membership') }</NavLink>
            </NavItem>
            <NavItem className="login">
              <NavLink href="https://app.saieve.io/account/login"> { t('menu.login') } </NavLink>
            </NavItem>
            <NavItem className="earning">
              <NavLink href="https://app.saieve.io/account/register"> { t('menu.get_started') } <img src={Vector} alt="Vector"/> </NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar className="login">
              <DropdownToggle nav caret className="login">
                {selectedLang.name}
              </DropdownToggle>
              <DropdownMenu right>
                {
                  languages.map(lang => (
                    <DropdownItem
                      key={lang.code}
                      onClick={() => changeLang(lang)}
                    >
                      {lang.name}
                    </DropdownItem>
                  ))
                }
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
}

export default Menu;
import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { Container } from 'reactstrap';

const LastPart: React.FC = () => {
  return (
    <Container>
      <WrapperBox>
        <Title>
          Do <Deco>more</Deco> with your money
        </Title>
        <a rel="noreferrer" href="https://bit.ly/3SpQhCE" target="_blank" style={{ textDecoration: 'none' }}>
          <AccessBut>GET EARLY ACCESS</AccessBut>
        </a>
        <ImgBox top={'37px'} right={'37px'}>
          <img src="/images/invest/Ethereum.png" alt="Ethereum" />
        </ImgBox>
        <ImgBox bottom={'0px'} left={'2px'}>
          <img src="/images/invest/Yearn.png" alt="Yearn" />
        </ImgBox>
      </WrapperBox>
      <ImgBox top={'-60px'} right={'300px'}>
        <img src="/images/invest/Cardano.png" alt="Cardano" />
      </ImgBox>
      <ImgBox bottom={'90px'} left={'360px'}>
        <img src="/images/invest/Tether.png" alt="Tether" />
      </ImgBox>
      <ImgBox bottom={'-10px'} right={'60px'}>
        <img src="/images/invest/Shopping_basket.png" alt="Shopping_basket" />
      </ImgBox>
    </Container>
  );
};

const ImgBox = styled(Box)`
  display: flex;
  position: absolute;
  @media (max-width: 800px) {
    display: none;
  }
`;

const AccessBut = styled(Box)`
  background: #a47ee6;
  border-radius: 6px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  padding: 20px;
  width: fit-content;
  &:hover {
    opacity: 0.7;
  }
`;

const Title = styled.span`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  color: #f9fafb;
`;

const Deco = styled.span`
  background: #a47ee6;
  border-radius: 6px;
  padding: 5px;
`;

const WrapperBox = styled(Box)`
  z-index: 1;
  position: relative;
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 35px;
  padding: 37px 0px 43px 0px;
  @media (max-width: 800px) {
    padding-left: 15px;
    text-align: center;
    padding-right: 15px;
    width: 100%;
  }
`;

export default LastPart;

import React from 'react';
import {useTranslation} from "react-i18next";
import Slider from './Slider';
import line from '../../images/line.png';
import {Container, Row, Col} from 'reactstrap';


const Features = () => {
  const { t } = useTranslation('home')

  return (
    <>
      <section className="features-section">
        <Container>
          <Row>
            <Col>
              <img src={line} alt="line" className="line"/>
              <h2 className="feature-title">{t('feature.title')}</h2>
              <h3 id="feature_sec" className="large_txt">{t('feature.extra_title')}</h3>
            </Col>
            <Slider/>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default Features;
 
import React from 'react';
import Menu from './Menu';
import {Container, Row, Col} from 'reactstrap';

const Header = () => {

  window.addEventListener('scroll',function(e) {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      document.getElementById('header')?.classList.add("sticky")
    } else {
      document.getElementById('header')?.classList.remove("sticky");
    }
  });

  return (
    <>
      <header id="header" className="header-section">
        <Container>
          <Row>
            <Col><Menu/></Col>
          </Row>
        </Container>
      </header>
    </>
  )
}
export default Header;

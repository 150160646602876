import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import { Container } from 'reactstrap';

const FourthPart: React.FC = () => {
  return (
    <StyledContainer>
      <Container fixed maxWidth={'lg'}>
        <WrapperBox>
          <MainBox>
            <Box>
              <Title>
                Invest in ideas,
                <br />
                not tokens
              </Title>
              <Desc>
                Baskets are an avenue to simplify your crypto journey.
                <br /> You can invest in one-click to express an investment
                opinion on a winder market segment.
              </Desc>
            </Box>
            <ImgBox>
              <img src="./images/invest/ideas.png" alt="ideas" />
            </ImgBox>
          </MainBox>
          <IdeasBox>
            <IdeaItem>
              <IdeaIcon>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.5 2H8.5V1H3.5V2ZM4 7.5V1.5H3V7.5H4ZM8.5 7H3.5V8H8.5V7ZM11 4.5C11 5.88071 9.88071 7 8.5 7V8C10.433 8 12 6.433 12 4.5H11ZM8.5 2C9.88071 2 11 3.11929 11 4.5H12C12 2.567 10.433 1 8.5 1V2ZM3.5 8H9.5V7H3.5V8ZM9.5 13H3.5V14H9.5V13ZM4 13.5V7.5H3V13.5H4ZM12 10.5C12 11.8807 10.8807 13 9.5 13V14C11.433 14 13 12.433 13 10.5H12ZM9.5 8C10.8807 8 12 9.11929 12 10.5H13C13 8.567 11.433 7 9.5 7V8ZM2 2H3.5V1H2V2ZM2 14H3.5V13H2V14ZM2 8H3.5V7H2V8ZM3 0V1.5H4V0H3ZM4 15V13.5H3V15H4ZM7 0V1.5H8V0H7ZM7 13.5V15H8V13.5H7Z"
                    fill="url(#paint0_linear_1015_6565)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1015_6565"
                      x1="12.7669"
                      y1="15"
                      x2="-1.83189"
                      y2="8.05581"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#0420B6" />
                      <stop offset="1" stop-color="#304CE3" />
                    </linearGradient>
                  </defs>
                </svg>
              </IdeaIcon>
              <IdeaContent>
                <IdeaTitle>Baskets of Crypto Tokens</IdeaTitle>
                <IdeaDesc>Based on themes that you could relate with</IdeaDesc>
              </IdeaContent>
            </IdeaItem>
            <IdeaItem>
              <IdeaIcon>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.00746 4.4005L9.04072 3.9016L9.00746 4.4005ZM2.95478 8.13786L2.49374 7.94437L2.95478 8.13786ZM7.5 14C5.87329 14 4.39916 13.042 3.73849 11.5555L2.82468 11.9616C3.64585 13.8093 5.4781 15 7.5 15V14ZM11.2615 11.5555C10.6008 13.042 9.12671 14 7.5 14V15C9.5219 15 11.3541 13.8093 12.1753 11.9616L11.2615 11.5555ZM2 15V6H1V15H2ZM7.5 1C10.5376 1 13 3.46243 13 6.5H14C14 2.91015 11.0899 0 7.5 0V1ZM2 6.5C2 3.46243 4.46243 1 7.5 1V0C3.91015 0 1 2.91015 1 6.5H2ZM13 6.5V15H14V6.5H13ZM8.9742 4.89939L10.4667 4.99889L10.5333 4.00111L9.04072 3.9016L8.9742 4.89939ZM11 4.5V3H10V4.5H11ZM3.41582 8.33136C4.32054 6.17573 6.51869 4.73569 8.9742 4.89939L9.04072 3.9016C6.14682 3.70868 3.55895 5.40634 2.49374 7.94437L3.41582 8.33136ZM10.1464 4.85355C10.6164 5.32348 11.4761 6.3438 11.8352 8.19581L12.8169 8.00545C12.4098 5.90613 11.4221 4.71504 10.8536 4.14645L10.1464 4.85355ZM6.5 13H8V12H6.5V13ZM8 13C9.10457 13 10 12.1046 10 11H9C9 11.5523 8.55228 12 8 12V13ZM12.5 9H10.5V10H12.5V9ZM8.5 8H12V7H8.5V8ZM3.5 8H6.5V7H3.5V8ZM6.5 8H8.5V7H6.5V8ZM4.5 9H2.5V10H4.5V9ZM6 7.5C6 8.32843 5.32843 9 4.5 9V10C5.88071 10 7 8.88071 7 7.5H6ZM10.5 9C9.67157 9 9 8.32843 9 7.5H8C8 8.88071 9.11929 10 10.5 10V9ZM3.73849 11.5555C3.2427 10.44 3.00886 9.30102 3.41582 8.33136L2.49374 7.94437C1.92348 9.30312 2.29491 10.7696 2.82468 11.9616L3.73849 11.5555ZM12.1753 11.9616C12.7043 10.7715 13.085 9.38793 12.8169 8.00545L11.8352 8.19581C12.0466 9.2858 11.7554 10.4442 11.2615 11.5555L12.1753 11.9616Z"
                    fill="url(#paint0_linear_1015_6571)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1015_6571"
                      x1="13.8051"
                      y1="15"
                      x2="-1.73855"
                      y2="6.2588"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#0420B6" />
                      <stop offset="1" stop-color="#304CE3" />
                    </linearGradient>
                  </defs>
                </svg>
              </IdeaIcon>
              <IdeaContent>
                <IdeaTitle>Created by Experts</IdeaTitle>
                <IdeaDesc>Coins and allocations managed by experts</IdeaDesc>
              </IdeaContent>
            </IdeaItem>
            <IdeaItem>
              <IdeaIcon>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.5 14.5H0V15H0.5V14.5ZM2 11.5V12H3V11.5H2ZM15 2.5V2H14V2.5H15ZM0 0V14.5H1V0H0ZM0.5 15H15V14H0.5V15ZM3 11.5C3 10.0456 3.24378 8.6201 3.70691 7.57807C4.17757 6.51908 4.79814 6 5.5 6V5C4.20186 5 3.32243 5.98092 2.79309 7.17193C2.25622 8.3799 2 9.95441 2 11.5H3ZM5.5 6C5.82076 6 6.14191 6.15761 6.50461 6.4924C6.87081 6.83043 7.21786 7.29048 7.6 7.8C7.96786 8.29048 8.37081 8.83043 8.81711 9.2424C9.26691 9.65761 9.82076 10 10.5 10V9C10.1792 9 9.85809 8.84239 9.49539 8.5076C9.12919 8.16957 8.78214 7.70952 8.4 7.2C8.03214 6.70952 7.62919 6.16957 7.18289 5.7576C6.73309 5.34239 6.17924 5 5.5 5V6ZM10.5 10C11.7232 10 12.8626 9.23726 13.6727 7.9545C14.4853 6.66802 15 4.8191 15 2.5H14C14 4.6809 13.5147 6.33198 12.8273 7.4205C12.1374 8.51274 11.2768 9 10.5 9V10Z"
                    fill="url(#paint0_linear_1015_6577)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1015_6577"
                      x1="15.3686"
                      y1="14.5"
                      x2="-1.54375"
                      y2="3.00762"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#0420B6" />
                      <stop offset="1" stop-color="#304CE3" />
                    </linearGradient>
                  </defs>
                </svg>
              </IdeaIcon>
              <IdeaContent>
                <IdeaTitle>Manage Risk Better</IdeaTitle>
                <IdeaDesc>
                  Diversify automatically for long-term investments
                </IdeaDesc>
              </IdeaContent>
            </IdeaItem>
          </IdeasBox>
        </WrapperBox>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  margin-top: 135px;
  @media (max-width: 800px) {
    text-align: center;
  }
`;

const ImgBox = styled(Box)`
  margin-top: -120px;
  margin-right: -30px;
`;

const MainBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 150px;
  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
  }
`;

const IdeaDesc = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */

  /* Secondary/Grey 1 */

  color: #7d91ae;
`;

const IdeaTitle = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: #f9fafb;
`;

const IdeaContent = styled(Box)``;

const IdeaIcon = styled(Box)`
  background: #f9fafb;
  border-radius: 8px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IdeaItem = styled(Box)`
  display: flex;
  grid-column-gap: 20px;
  /* width: 340px; */
`;

const IdeasBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-top: 72px;
  flex-direction: row;
  gap: 22px;

  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Desc = styled(Box)`
  margin-top: 10px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #f9fafb;
`;

const Title = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 150%;
  color: #f9fafb;
`;

const WrapperBox = styled(Box)`
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  box-shadow: 0px 0px 54.3813px rgba(0, 23, 143, 0.2);
  border-radius: 10px;
  padding: 64px 64px 60px 64px;
  margin-left: auto;
  margin-left: auto;
  width: 1200px;
  margin-right: auto;

  @media (max-width: 1215px) {
    width: 100%;
    padding: 15px;
  }
`;

export default FourthPart;

import React from "react";
import Header from "../../components/Header";
import Build from "../../components/Home/Build";
// import Earn from "../../components/Home/Earn";
// import Savings from "../../components/Home/Savings";
// import Calculate from "../../components/Home/Calculate";
// import Goldmembers from "../../components/Home/Goldmembers";
import HowWorks from "../../components/Home/How_works";
import Grow from "../../components/Home/Grow";
import Visual from "../../components/Home/Visual";
// import Membership from "../../components/Home/Membership";
import Features from "../../components/Home/Features";
import Earnpcl from "../../components/Home/EarnPcl";
import Faq from "../../components/Home/Faq";
import Footer from "../../components/Footer";
import Investing from "../../components/Investing/index";

const Home = () => {
  // const [percent, setPercent] = useState(0);
  // const [date, setDate] = useState('01/06/2021');
  // const handleCallback = (apy1Percent: number) =>{
  //   setPercent(apy1Percent)
  // }
  // const handleDate = (date: string) =>{
  //   setDate(date)
  // }

  return (
    <>
      <Header/>
      <Build/>
      {/* <Earn percent={percent}/>
      <Savings percent={percent} date={date}/>
      <Calculate getApy1yPercent={handleCallback} getDate={handleDate}/>
      <Goldmembers/> */}
      <Investing/>
      <HowWorks/>
      <Grow/>
      <Visual/>
      {/* <Membership/> */}
      <Features/>
      <Earnpcl/>
      <Faq/>
      <Footer/>
    </>
  )
}

export default Home;
import React from 'react';
import {Trans} from 'react-i18next'
import arrows from '../../images/arr.png';
import {UncontrolledCollapse, Button, CardBody, Card} from 'reactstrap';

const Faq_detail = () => (
  <>
    <div className="faq-box">
      <Button color="primary" id="q0" style={{marginBottom: '1rem'}}>
        <Trans i18nKey="home:faq.q1">
          What is <span className="font-sansation">SAIΞVE</span> App?
        </Trans> <img src={arrows} alt="arrows"/>
      </Button>
      <UncontrolledCollapse toggler="#q0">
        <Card>
          <CardBody>
            <Trans i18nKey="home:faq.r1">
              The <span className="font-sansation">SAIΞVE</span> App is a convenient wealth management web app that offers fast, easy access to your Earning
              accounts. <br/>
              It combines AI and Smart Contracts, implementing elements from traditional savings with all-data-driven
              decision investments making.
            </Trans>
          </CardBody>
        </Card>
      </UncontrolledCollapse>
    </div>
    <div className="faq-box">
      <Button color="primary" id="q1" style={{marginBottom: '1rem'}}>
        <Trans i18nKey="home:faq.q2">
          What is <span className="font-sansation">SAIΞVE</span> Earn?
        </Trans> <img src={arrows} alt="arrows"/>
      </Button>
      <UncontrolledCollapse toggler="#q1">
        <Card>
          <CardBody style={{textAlign: "justify"}}>
            <Trans i18nKey="home:faq.r2">
              Earn is a handy aggregation of ways to earn yield on your crypto portfolio. We provide the best options from
              PECULIUM, so that you can maximize your earning potential options. <span className="font-sansation">SAIΞVE</span> Earn is an investment product for
              users to manage idle Digital Assets in their exchanges. With Earn, users can earn more profits by holding
              PCL tokens.
            </Trans>

          </CardBody>
        </Card>
      </UncontrolledCollapse>
    </div>
    <div className="faq-box">
      <Button color="primary" id="q2" style={{marginBottom: '1rem'}}>
        <Trans i18nKey="home:faq.q3">
          What is <span className="font-sansation">BELIΞVE</span> Account?
        </Trans> <img src={arrows} alt="arrows"/>
      </Button>
      <UncontrolledCollapse toggler="#q2">
        <Card>
          <CardBody style={{textAlign: "justify"}}>
            <Trans i18nKey="home:faq.r3">
              You can think of it as your “<span className="font-sansation">SAIΞVE</span> crypto savings account”, the first account of its kind. The product
              should be seen as more of an investment than a savings account. It lets investors put their passive
              Stablecoins Assets to work and earn a return at competitive yields. The difference between a regular savings
              account and this one is that Stablecoins are capable of so much more!
            </Trans>
          </CardBody>
        </Card>
      </UncontrolledCollapse>
    </div>
    <div className="faq-box">
      <Button color="primary" id="q3" style={{marginBottom: '1rem'}}>
        <Trans i18nKey="home:faq.q4">
          What are the risks?
        </Trans> <img src={arrows} alt="arrows"/>
      </Button>
      <UncontrolledCollapse toggler="#q3">
        <Card>
          <CardBody style={{textAlign: "justify"}}>
            <Trans i18nKey="home:faq.r4">
              Cryptocurrency, like many Assets, can be volatile and subject to price swings. There is always a risk in
              investing, and each customer needs to assess their own risk tolerance before making any investment
              decisions. We believe <span className="font-sansation">SAIΞVE</span> Earn gives our retail investors another way to stay long-term in the Asset
              class and have the option to invest and earn yield, all on the Binance platform.
            </Trans>
          </CardBody>
        </Card>
      </UncontrolledCollapse>
    </div>
    <div className="faq-box">
      <Button color="primary" id="q4" style={{marginBottom: '1rem'}}>
        <Trans i18nKey="home:faq.q5">
          What is APY rate ?
        </Trans> <img src={arrows} alt="arrows"/>
      </Button>
      <UncontrolledCollapse toggler="#q4">
        <Card>
          <CardBody style={{textAlign: "justify"}}>
            <Trans i18nKey="home:faq.r5">
              When you use Earn, you will gain yield on your subscription. The income from the yield determines the rate
              or annual percentage yield (APY). Once you have realized some yield, you can calculate your net rate using
              the sum of the annualized yield, which change in real time according to market conditions.
            </Trans>
          </CardBody>
        </Card>
      </UncontrolledCollapse>
    </div>
  </>
);

export default Faq_detail;
import Home from "../pages/Home/Home";
import Marketing from "../pages/Marketing/Marketing";


export const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/start',
    component: Marketing
  }
];
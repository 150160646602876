import React from 'react';
import {useTranslation, Trans} from "react-i18next";
import predicate from '../../images/predicate.svg';
import {Container, Row, Col} from 'reactstrap';


const Visual = () => {
  const { t } = useTranslation('home')

  return (
    <>
      <section id="visual-section" className="visual-section">
        <Container>
          <Row>
            <Col>
              <img src={predicate} className="visul-img" alt="visualimg"/>
              <h2>{t('visual.title')}</h2>
              <p>
                <Trans
                  i18nKey="home:visual.desc"
                  components={[<span className="font-sansation">univers</span>]}
                />

              </p>
              <div className="login-btns">
                <a href="https://app.saieve.io/supported-assets" className="log">{t('button.learn_more')}</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default Visual;
 
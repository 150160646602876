import React from "react";
import Header from "../../components/Marketing/Header";
import Build from "../../components/Marketing/Build";
import Calculate from "../../components/Home/Calculate";
import HowWorks from "../../components/Marketing/How_works";
import Faq from "../../components/Marketing/Faq";
import Footer from "../../components/Footer";


const Marketing = () => {

  const handleCallback = () =>{
    return
  }
  const handleDate = () =>{
    return
  }

  return (
    <>
      <Header/>
      <Build/>
      <Calculate getApy1yPercent={handleCallback} getDate={handleDate}/>
      <HowWorks/>
      <Faq/>
      <Footer/>
    </>
  )
}

export default Marketing;
import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

const IntroPart: React.FC = () => {
  return (
    <StyledContainer>
      <WrapperBox>
        <TextPart>
          <Badge>SOON</Badge>
          <Title>
            SAIΞVE <Deco>Baskets</Deco>
          </Title>
          <Desc>Diversification made easy</Desc>
          <SDesc>
            Single tokens aren’t the only way to invest. To build long term
            wealth, you need a diversified portfolio. Get early access to
            baskets designed by our experts, then, explore, ass, and adjust your
            investments to match your interests
          </SDesc>
          <a rel="noreferrer" href="https://bit.ly/3SpQhCE" target="_blank" style={{ textDecoration: 'none'}}>
            <AccessButton>GET EARLY ACCESS</AccessButton>
          </a>
        </TextPart>
        <ImgBox src="./images/invest/coinbucket.png" alt="coinbucket" />
      </WrapperBox>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  padding-top: 75px;
  padding-bottom: 75px;
  @media (max-width: 800px) {
    height: fit-content;
    text-align: center;
  }
`;

const WrapperBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ImgBox = styled.img`
  width: 55%;
  transform: translateY(80px) translateX(120px);
  @media (max-width: 800px) {
    width: 100%;
    transform: translateY(0px) translateX(0px);
  }
`;

const TextPart = styled(Box)`
  /* display: flex; */
  padding-top: 50px;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
  }
`;

const Badge = styled(Box)`
  background: #a47ee6;
  border-radius: 4px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #f9fafb;

  padding: 5.5px 12px;
  width: fit-content;
`;

const Title = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: #022b69;
`;

const Deco = styled.span`
  background: #0220bf;
  border-radius: 2px;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
`;

const Desc = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 150%;
  color: #022b69;
`;

const SDesc = styled(Box)`
  margin-top: 30px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #7d91ae;
`;

const AccessButton = styled(Box)`
  margin-top: 30px;
  background: #0220bf;
  border-radius: 6px;
  text-decoration: none;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #f9fafb;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  padding: 12px 20px;
  width: fit-content;
  @media (max-width: 800px) {
    margin-bottom: 50px;
    text-align: center;
  }
`;

export default IntroPart;

import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Grid, TextField, InputAdornment } from '@mui/material';
import { Container } from 'reactstrap';
import { SearchOutlined } from '@mui/icons-material';
import Typed from 'react-typed';

const SecondPart: React.FC = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handResize = () => {
    setWindowSize(window.innerWidth);
  };

  window.addEventListener('resize', handResize);
  const Texts = [
    'Decentralized Finance',
    'Metaverse',
    'NFT',
    'Gaming',
    'Smart Contracts',
    'Distributed Computing',
    'Internet of Things',
    'Move-to-earn',
    'And many more ...',
  ];
  return (
    <StyledContainer>
      <Container fixed maxWidth={'lg'}>
        <svg
          width="66"
          height="64"
          viewBox="0 0 66 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.3">
            <ellipse
              cx="41.7846"
              cy="4"
              rx="4"
              ry="4.04348"
              transform="rotate(90 41.7846 4)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="41.7846"
              cy="22.6667"
              rx="4"
              ry="4.04348"
              transform="rotate(90 41.7846 22.6667)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="41.7846"
              cy="41.3335"
              rx="4"
              ry="4.04348"
              transform="rotate(90 41.7846 41.3335)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="41.7846"
              cy="60"
              rx="4"
              ry="4.04348"
              transform="rotate(90 41.7846 60)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="60.9585"
              cy="4"
              rx="4"
              ry="4.04348"
              transform="rotate(90 60.9585 4)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="60.9585"
              cy="22.6667"
              rx="4"
              ry="4.04348"
              transform="rotate(90 60.9585 22.6667)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="60.9585"
              cy="41.3335"
              rx="4"
              ry="4.04348"
              transform="rotate(90 60.9585 41.3335)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="60.9585"
              cy="60"
              rx="4"
              ry="4.04348"
              transform="rotate(90 60.9585 60)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="22.9155"
              cy="4"
              rx="4"
              ry="4.04348"
              transform="rotate(90 22.9155 4)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="22.9155"
              cy="22.6667"
              rx="4"
              ry="4.04348"
              transform="rotate(90 22.9155 22.6667)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="22.9155"
              cy="41.3335"
              rx="4"
              ry="4.04348"
              transform="rotate(90 22.9155 41.3335)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="22.9155"
              cy="60"
              rx="4"
              ry="4.04348"
              transform="rotate(90 22.9155 60)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="4.04441"
              cy="4"
              rx="4"
              ry="4.04348"
              transform="rotate(90 4.04441 4)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="4.04441"
              cy="22.6667"
              rx="4"
              ry="4.04348"
              transform="rotate(90 4.04441 22.6667)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="4.04441"
              cy="41.3335"
              rx="4"
              ry="4.04348"
              transform="rotate(90 4.04441 41.3335)"
              fill="#F9FAFB"
            />
            <ellipse
              cx="4.04441"
              cy="60"
              rx="4"
              ry="4.04348"
              transform="rotate(90 4.04441 60)"
              fill="#F9FAFB"
            />
          </g>
        </svg>
        <WrapperBox>
          <ContentBox>
            <ImgBox>
              <img src="./images/invest/portfolio.svg" alt="portfolio" />
            </ImgBox>
          </ContentBox>
          <TextPart>
            <Title>Build a portfolio</Title>
            <Badge>You believe in</Badge>
            <Desc>
              We offer portfolios curated for interests like DeFi, Metaverse,
              along with hundreds of products in categories like energy, storage
              to let you customize your portfolio exactly how you want. And we
              make it easy and safe to experiment by letting you know if your
              choices are in line with your risk level
            </Desc>
          </TextPart>
        </WrapperBox>
        <Grid
          container
          flexDirection={'column'}
          style={{ color: '#fff' }}
          gap={'135px'}
          marginTop={'135px'}
        >
          <Grid
            container
            item
            flexDirection={'row'}
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
            alignItems={{ xs: 'center' }}
            gap={24}
            rowGap={4}
          >
            <Grid container item flexDirection={'column'} xs={12} md={6}>
              <Grid item>
                <h2>Make it custom with</h2>
              </Grid>
              {windowSize <= 800 ? (
                <Grid item>
                  <TextField
                    fullWidth
                    variant="filled"
                    InputProps={{
                      style: {
                        color: 'white',
                        fontSize: '22px',
                        borderColor: 'white',
                      },

                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlined style={{ color: 'white' }} />
                          <Typed
                            strings={Texts}
                            typeSpeed={150}
                            backSpeed={100}
                            style={{ color: 'white', marginLeft: '10px' }}
                            loop
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ) : (
                <Grid item>
                  <p style={{ fontSize: '18px', fontWeight: '400' }}>
                    Explore hundreds of funds across dozens of categories,
                    including social responsibility, clean energy, tech and
                    more.
                  </p>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {windowSize > 800 ? (
                <Grid item>
                  <TextField
                    fullWidth
                    variant="filled"
                    InputProps={{
                      style: {
                        color: 'white',
                        fontSize: '22px',
                        borderColor: 'white',
                      },

                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlined style={{ color: 'white' }} />
                          <Typed
                            strings={Texts}
                            typeSpeed={150}
                            backSpeed={100}
                            style={{ color: 'white', marginLeft: '10px' }}
                            loop
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ) : (
                <Grid item>
                  <p style={{ fontSize: '18px', fontWeight: '400' }}>
                    Explore hundreds of funds across dozens of categories,
                    including social responsibility, clean energy, tech and
                    more.
                  </p>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={3}>
              <StyledBox>
                <p>Beginner Basket</p>
                <span style={{ lineHeight: '26px' }}>
                  Beginner baskets is Average Joe’s recommended tokens to hold
                  if you’re starting your journey in Crypto.
                </span>
              </StyledBox>
            </Grid>
            <Grid item xs={6} lg={3}>
              <StyledBox
                alignItems={'center'}
                style={{
                  background:
                    'linear-gradient(144.55deg, #0067F5 0%, #4493FF 100%)',
                }}
              >
                <p style={{ color: '#fff', marginBottom: '0', }}>Inflation hedge basket</p>
                <img
                  src="./images/hedgalloc.png"
                  style={{ height: '150px' }}
                  alt="coin"
                />
                <p
                  style={{
                    fontWeight: 'bold',
                    color: '#0220bf',
                    marginBottom: '0',
                  }}
                >
                  ALL PERIOD P&L
                </p>
                <p
                  style={{
                    color: '#fff',
                    fontWeight: '700',
                    fontSize: '28px',
                    marginBottom: '0',
                  }}
                >
                  +40.11%
                </p>
                <img src="./images/hcoins.png" alt="coin"/>
              </StyledBox>
            </Grid>
            <Grid item xs={6} lg={3}>
              <StyledBox>
                <p>Decentalized Finance Leaders baskets</p>
                <span style={{ lineHeight: '26px' }}>
                  DeFi leadres are digital assets that provide traditional
                  financial products – such as exchanges, loans, derivatives,
                  and savings accounts – ouside of traditional financial
                  institutions and with no middlemen. It consist of many of the
                  industry’s most exciting and established projects.
                </span>
              </StyledBox>
            </Grid>

            <Grid item xs={6} lg={3}>
              <StyledBox
                alignItems={'center'}
                style={{
                  background:
                    'linear-gradient(144.55deg, #0067F5 0%, #4493FF 100%)',
                }}
              >
                <p style={{ color: '#fff', marginBottom: '0', }}>Smart Contracts</p>
                <img
                  src="./images/smalloc.png"
                  style={{ height: '150px' }}
                  alt="coin"
                />
                <p
                  style={{
                    fontWeight: 'bold',
                    color: '#0220bf',
                    marginBottom: '0',
                  }}
                >
                  ALL PERIOD P&L
                </p>
                <p
                  style={{
                    color: '#fff',
                    fontWeight: '700',
                    fontSize: '28px',
                    marginBottom: '0',
                  }}
                >
                  +883.90%
                </p>
                <img src="./images/smcoins.png" alt="coin" />
              </StyledBox>
            </Grid>
          </Grid>
        </Grid>
        <Glass
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          marginTop={'22px'}
        >
          <h1>
            {' '}
            Explore <b>hundreds</b> of investment options{' '}
            <a rel="noreferrer" href="https://bit.ly/3SpQhCE" target="_blank" style={{ textDecoration: 'none' }}>
              <b>&gt;&gt;</b>
            </a>
          </h1>
        </Glass>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  background: linear-gradient(304.2deg, #0420b6 -1.51%, #304ce3 100%);
  height: fit-content;
  display: flex;
  padding-bottom: 135px;
  padding-top: 165px;
  align-items: center;

  @media (max-width: 800px) {
    text-align: center;
  }
`;

const Glass = styled(Box)`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 70px;
  padding-bottom: 59px;
  h1 {
    color: white;
  }
  b {
    color: #0220bf;
  }
`;
const Desc = styled(Box)`
  margin-top: 30px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #f9fafb;
`;
const Badge = styled(Box)`
  background: #a47ee6;
  border-radius: 6px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  color: #f9fafb;
  padding: 5px 16px;
  width: fit-content;
`;
const Title = styled(Box)`
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  color: #f9fafb;
`;

const StyledBox = styled(Box)`
  background: #e5e5e5;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-height: 400px;
  padding-top: 20px;
  padding-left: 19px;
  padding-bottom: 54px;
  padding-right: 19px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-left: 20px;

  gap: 10px;

  p {
    color: #022b69;
    font-size: 20px;
    font-weight: bold;
  }
  span {
    color: #7d91ae;
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
  }

  @media (max-width: 800px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

const TextPart = styled(Box)`
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  }
`;
const ImgBox = styled(Box)``;

const ContentBox = styled(Box)`
  padding-left: 40px;
`;
const WrapperBox = styled(Box)`
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  column-gap: 85px;
  align-items: center;

  @media (max-width: 1215px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;
export default SecondPart;

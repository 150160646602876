import React from 'react';
import { useTranslation } from "react-i18next";
import coins from '../../images/Coins.svg';
import check from '../../images/check.svg';
import Vector from '../../images/vector.png';
import {Container, Row, Col} from 'reactstrap';


const EarnPcl = () => {
  const { t } = useTranslation('home')

  return (
    <>
      <section className="earn-section">
        <Container style={{ paddingBottom: 70 }}>
          <Row>
            <Col sm="12" className="title-sec">
              <img src={coins} alt="coins"/>
              <h3 id="membership">
              Our Pricing Plans
              </h3>
              <p>
              Choose a plan that fits you
              </p>
            </Col>
            <Col
            lg={{
              offset: 1,
              size: '5'
            }}
            md={{
              offset: 0,
              size: '6'
            }}
            >
              <div className="member-pan">
                <h4>SAIΞVE Lite</h4>
                <h4>9.99$/m</h4>
                <div className="plan-body">
                  <h4>Digital investing</h4>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>All supported exchanges</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Up to 10 Standard Pre-built thematic Baskets</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Minimum Investment per basket: 200$</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Portfolio analytics</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Risk-based Portfolio Management</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Auto-pilot features</p>
                  </div>
                  <ul style={{ textAlign: 'left', color: '#777' }}>
                    <li>Portfolio rebalancing </li>
                    <li>Regular deposits <span className="soon">soon</span></li>
                    <li>Intelligent weighting <span className="soon">soon</span></li>
                  </ul>
                </div>
                <div className="login-btns">
                  <a href="https://app.saieve.io/user/plans" className="start-earn">{t('button.get_started')}
                  <img src={Vector} alt="vector"/>
                  </a>
                </div>
              </div>
            </Col>
            <Col
              lg={{
                size: '5'
              }}
              md={{
                size: '6'
              }}
            >
              <div className="member-pan">
                <h4>SAIΞVE Premium</h4>
                <h4>19.99$/m</h4>
                <div className="plan-body">
                  <h4>Premium Investing</h4>
                  <div className="plan-list">
                    <p style={{ color: 'black' }}>Everything in SAIΞVE Lite</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Up to 40 Premium pre-built thematic baskets</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Actively managed portfolio</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Minimum investment per basket: 500$</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Rebates on trading fees</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Passive extra interest on your holdings through DeFi protocols <span className="soon">soon</span></p>
                  </div>
                  <h4>Invest according to your values <span className="soon">soon</span></h4>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Environment Socially responsible investing options</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Halal investing that is carefully screened to comply with Islamic law</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Asset location</p>
                  </div>
                  <h4>Advice and planning tools</h4>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Priority Customer support</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>A dedicated team of advisors</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Access to on-demand investment advice</p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Custom Goal-based Investment Path <span className="soon">soon</span></p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Tax reporting <span className="soon">soon</span></p>
                  </div>
                  <div className="plan-list">
                    <img src={check} alt="check"/>
                    <p>Feedback on accounts you hold at other institutions</p>
                  </div>
                </div>
                <div className="login-btns">
                  <a href="https://app.saieve.io/user/plans" className="start-earn">{t('button.get_started')}
                  <img src={Vector} alt="vector"/>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
export default EarnPcl;
 
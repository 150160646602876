import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';
import Routes from "./routes";

function App() {

  return (
    <Suspense fallback="loading">
      <Router>
        <Routes />
      </Router>
    </Suspense>
  );
}

export default App;
